import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from '../axiosClient';

export const login = createAsyncThunk('auth/login', async ({ email, password }, { rejectWithValue }) => {
    try {
        const response = await axiosClient.post(`/user/login`, { email, password });
        localStorage.setItem('token', response.data.data.token);
        console.log('response is ', response.data.data);
        return response.data.data;

    } catch (error) {
        
      
      // Check if error.response exists
      if (error.response) {
        return rejectWithValue(error.response.data);
    } else if (error.request) {
      return rejectWithValue({ message: 'An error occurred due to network connectivity, kindly check your internet connection and try again.' });

    } else {
        return rejectWithValue({ message: error.message });
    }
    }
});



    

// export const register = createAsyncThunk('auth/register', async (userData, { rejectWithValue }) => {
//   try {
//       const response = await axiosClient.post(`/v1/register`, userData);
//       console.log('reg response is', response.data.message);

//       if (response.data.message === 'Registration Succesfull') {
//           return { message: response.data.message };
//       } else {
//           throw new Error('Unexpected registration message');
//       }
//   } catch (error) {
      
    
    
//       return rejectWithValue(error.response.data);
 
  
//   }
// });

export const register = createAsyncThunk('auth/register', async (userData, { rejectWithValue }) => {
  try {
      const response = await axiosClient.post(`/v1/register`, userData);
      console.log('reg response is', response.data.message);



      return response.data.message ;

  } catch (error) {
      // Improved error handling
      const message = error.response?.data?.message || error.message || 'An unexpected error occurred';

      console.log(message)
      return rejectWithValue(message);
  }
});


export const createPin = createAsyncThunk('auth/createPin', async (userPin, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosClient.post('/v1/create/pin', userPin, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const message = response.data.message;
      console.log(message);
      return message;
    } catch (error) {

        console.log(error.response.data.message)
      return rejectWithValue(error.response.data.message );
    }
  });
export const checkPin = createAsyncThunk('auth/checkPin', async (userPin, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if(!token) throw new Error ('User not autenticated')
        console.log('check pin token ',token)
      
      const response = await axiosClient.post('/v1/check-pin', userPin, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const message = response.data.message;
      console.log('check pin  ', message);
      return message;
    } catch (error) {

        console.log(error.response.data.message)
      return rejectWithValue(error.response.data.message );
    }
  });



export const helpSupport = createAsyncThunk('auth/helpSupport', async (messsageBody, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosClient.post('/v1/help/support', messsageBody, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const message = response.data.message;
      console.log('Message is ', message);
      return message;
    } catch (error) {

        console.log(error.response.data.message)
      return rejectWithValue(error.response.data.message );
    }
  });
export const addBank = createAsyncThunk('auth/addBank', async (messsageBody, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosClient.post('/v1/add/account', messsageBody, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const message = response.data.message;
      console.log('Message is ', message);
      return message;
    } catch (error) {

        console.log(error.response.data.message)
      return rejectWithValue(error.response.data.message );
    }
  });




export const addBeneficiary = createAsyncThunk('auth/addBeneficiary', async (userBen, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosClient.post('/v1/add/beneficiary', userBen, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const message = response.data.message;
      console.log(message);
      return message;
    } catch (error) {

        console.log(error.response.data.message)
      return rejectWithValue(error.response.data.message );
    }
  });


// export const getApplication = createAsyncThunk('auth/getApplication', async (_, { getState, rejectWithValue }) => {
//     try {
     
//       const token = state.token;

//       console.log('token is ', token)
//       const state = getState();
//       // const user = state.auth.user;
      
//         const response = await axiosClient.get(`/admin/ideas-programme/applications?page_size&search`, {
//           headers: { Authorization: `Bearer ${token}` }
//         });
//         console.log('response is app', response);
//         console.log('user is loading and data is');
//         console.log(response.data.user);
//         return response.data.user;
    
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   });
  

export const getApplication = createAsyncThunk(
  'auth/getApplication',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.auth.token; // Adjust the path to your token

      console.log('token is ', token);
      const response = await axiosClient.get(`/admin/ideas-programme/applications?page_size&search`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      console.log('response is app', response.data.data);
      console.log('user is loading and data is', response.data.data);
      return response.data.data; 
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


    const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: [],
        loading: false,
        error: null,
        token: null,
        applications : null,
        beneError: null,
        previousRoute: '/',
		isAuthenticated: false,
        
    },
    reducers: {
        logout: (state) => {
          state.isAuthenticated = false;
          state.loading =false;
          state.user = null;
          state.token = null;
          state.applications =null;
          localStorage.removeItem('redirectPath');
        },
        savePreviousRoute: (state, action) => {
          state.previousRoute = action.payload;
        },
        loginSuccess: (state, action) => {
         
          state.isAuthenticated = true;
          // localStorage.setItem('token', action.payload); // Store token in local storage
        },
        clearBeneError: (state) => {
          state.beneError = null;
          state.error = null;
        },

        setUserBalance: (state, action) => {
          // state.user.walletBalance = action.payload;
          console.log(action.payload)
          return {
            ...state,
            user:{
              ...state.user,
              walletBalance: action.payload
            }
          }
        }
      
      },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
               
                state.loading = false;
                state.isAuthenticated =true;
                state.token = action.payload.token;
                state.user = action.payload.user;

            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || action.error.message;
                state.isAuthenticated = false;
            })
            

            .addCase(register.pending, (state) => {
              state.loading = true;
          })
          .addCase(register.fulfilled, (state, action) => {
              // state.user = action.payload;
              state.loading = false;
            
              
          })
          .addCase(register.rejected, (state, action) => {
              state.loading = false;
              state.error = action.payload?.errors || action.error.message;
          })

            .addCase(getApplication.pending, (state) => {
                state.loading = true;
                state.isAuthenticated = true;

            })
            .addCase(getApplication.fulfilled, (state, action) => {
                state.applications = action.payload;
                state.isAuthenticated = true;
                state.loading = false;
            })
            .addCase(getApplication.rejected, (state, action) => {
                state.loading = false;
                state.isAuthenticated = false;
                state.error = action.payload?.errors || action.error.errors;
            })
            .addCase(createPin.pending, (state) => {
                state.loading = true;
                state.isAuthenticated = true; // Set to false while waiting for authentication
                state.error = null
              })
              .addCase(createPin.fulfilled, (state, action) => {
                state.message = action.payload; // Assuming the payload is a message, not the user
                state.isAuthenticated = true;
                state.loading = false;
                state.error = null

              })
              .addCase(createPin.rejected, (state, action) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.error = action.payload || action.error.message; // Simplified error handling
              })
            .addCase(checkPin.pending, (state) => {
                state.loading = true;
                state.isAuthenticated = true; // Set to false while waiting for authentication
              })
              .addCase(checkPin.fulfilled, (state, action) => {
                state.message = action.payload; // Assuming the payload is a message, not the user
                state.isAuthenticated = true;
                state.loading = false;
              })
              .addCase(checkPin.rejected, (state, action) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.error = action.payload || action.error.message; // Simplified error handling
              })
            .addCase(helpSupport.pending, (state) => {
                state.loading = true;
                state.isAuthenticated = true; // Set to false while waiting for authentication
              })
              .addCase(helpSupport.fulfilled, (state, action) => {
                state.message = action.payload; // Assuming the payload is a message, not the user
                state.isAuthenticated = true;
                state.loading = false;
              })
              .addCase(helpSupport.rejected, (state, action) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.error = action.payload || action.error.message; // Simplified error handling
              })
            .addCase(addBeneficiary.pending, (state) => {
                state.loading = true;
                state.isAuthenticated = true; // Set to false while waiting for authentication
              })
              .addCase(addBeneficiary.fulfilled, (state, action) => {
                state.message = action.payload; // Assuming the payload is a message, not the user
                state.isAuthenticated = true;
                state.loading = false;
              })
              .addCase(addBeneficiary.rejected, (state, action) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.beneError = action.payload || action.error.message; // Simplified error handling
              })
            //   .addCase(updateUserImage.pending, (state) => {
            //     state.loading = true;
            //   })
            //   .addCase(updateUserImage.fulfilled, (state, action) => {
            //     state.user = action.payload;
            //     state.loading = false;
            //   })
            //   .addCase(updateUserImage.rejected, (state, action) => {
            //     state.error = action.payload;
            //     state.loading = false;
            //   })
              .addCase(addBank.pending, (state) => {
                state.loading = true;
              })
              .addCase(addBank.fulfilled, (state, action) => {
                state.message = action.payload;
                state.loading = false;
              })
              .addCase(addBank.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
              });
    },
});

export const { logout, savePreviousRoute, clearBeneError, loginSuccess, setUserBalance } = authSlice.actions;

export default authSlice.reducer;