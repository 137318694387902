import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import HomePage from "./pages/HomePage";
import MultiStepForm from "./pages/MultiStepForm";
import { NoMatch } from "./pages/NoMatch";
import DafaultDashboard from "./pages/Dashboard/DafaultDashboard";
import Dashboard from "./pages/Dashboard/Dashboard";
import Application from "./pages/Dashboard/Application";
import LoginPage from "./pages/LoginPage";
import ProtectedRoute from "./components/ProtectedRoute";



export const router = createBrowserRouter([

{
    path: "/",
    element: <App/>,
    children: [
        {
            path: "/",
            element: <HomePage/>

        },
        {
            path: "/apply",
            element: <MultiStepForm/>

        },
    
        {
            path: "*",
            element: <NoMatch/>

        },
       


    ],

   
},
{
    path: "login",
    element: <LoginPage />

},

{
    path: '/dashboard',
    element:<ProtectedRoute ><DafaultDashboard/> </ProtectedRoute>  ,
    children: [


        {
           path: '',
           element: <Dashboard /> 
        },
        {
           path: 'application',
           element: <Application /> 
        },
    ]
}

]);