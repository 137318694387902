import { useEffect, useRef, useState } from "react";
import {  popupBG, welcome_image, welcome_image_popup } from "../assets";
import { IoClose } from "react-icons/io5";

const Modal = () => {
  const [openModal, setModalIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const boxRef = useRef();

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setModalIsOpen(false), 200);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalIsOpen(true);
      setTimeout(() => setShowModal(true), 0);
    }, 1000); // Change this to the desired delay in milliseconds

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!openModal) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full px-2 bg-black bg-opacity-80 z-40">
      <div
        ref={boxRef}
        className={`  md:py-0 gap-4  w-full md:w-[700px] md:h-[400px] h-auto flex flex-col md:flex-row items-center justify-between relative rounded-lg transform transition-all duration-500 ease-in-out ${
          showModal ? 'translate-y-0 opacity-100 scale-100' : 'translate-y-[-50px] opacity-0 scale-90'
        }`}
      >
     

       <div className="flex w-full kkpx-[40px]  justify-between flex-col md:flex-row bg-white   h-[500px] md:h-[450px] rounded-lg ">

       <div className="h-[200px] relative md:h-full w-full   md:w-[44%]  rounded-l-lg">
          <img className="h-full  hidden md:block w-full rounded-l-lg" src={welcome_image} alt="Welcome Image" />
          <img className="h-full w-full md:hidden rounded-l-lg" src={welcome_image_popup} alt="Welcome Image" />
          <button
          onClick={handleClose}
          className='absolute top-1 right-1 p-2  md:hidden bg-transparent w-8 rounded-full'
          aria-label="Close Modal"
        >
         <IoClose className="text-2xl"/>
      

       
        </button>
        </div>


        <div className="relative w-full md:w-[56%] h-full !rounded-r-lg">
          <div className="absolute top-0  h-full w-full bg-cover bg-center rounded-r-lg" style={{ backgroundImage: `url(${popupBG})` }} />
          <button
          onClick={handleClose}
          className='absolute top-1 right-1 p-2 hidden md:block z-30  rounded-full'
          aria-label="Close Modal"
        >
         <IoClose className="text-2xl"/>
        </button>
          <div className="absolute w-full h-full flex gap-2 px-5 md:px-[37px] flex-col  justify-center  items-center rounded-r-lg">
            <h1 className="text-lg md:text-2xl font-semibold  w-full flex items-start justify-start">
              Enrollment Information !!!</h1>
            <p className="text-sm md:text-base text-left">
              The training program will span 6 months and will include an internship, recognized certification, and training classes. The format will be hybrid, combining both online and in-person sessions, with all physical classes held in Abuja.
            </p>
            <p className="text-sm md:text-base font-bold text-left">
              Please note that the application deadline is October 25th, 2024.
            </p>
          </div>
        </div>
        
       </div>
      </div>
    </div>
  );
};

export default Modal;
