import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';


const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, user, token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  

 
  // Check if the user is not authenticated, user is not present, and no token is available
  if (!isAuthenticated && !user && !token) {
    // dispatch(savePreviousRoute(location.pathname));
    localStorage.setItem('redirectPath', location.pathname);
    console.log('Redirecting from protected routes due to lack of authentication:', isAuthenticated);
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
