import React from 'react';
import { bannerFive, bannerFour, bannerOne, bannerSeven, bannerSix, bannerThree, bannerTwo } from "../assets"; // Make sure to import your images

const slides = [
  {
    imgSrc: bannerOne,
   path: 'https://digiliteracy.africa/',
  },
  {
    imgSrc: bannerTwo,
    path: 'https://digiliteracy.africa/',

    // title: 'Excellence',
  },
  {
    imgSrc: bannerThree,
    path: 'https://digiliteracy.africa/',

    // title: 'Collaboration',
  },
  {
    imgSrc: bannerFour,
    path: 'https://digiliteracy.africa/',

    // title: 'Collaboration',
  },
  {
    imgSrc: bannerFive,
    path: 'https://digiliteracy.africa/',

    // title: 'Collaboration',
  },
  {
    imgSrc: bannerSix,
    path: 'https://digiliteracy.africa/',

    // title: 'Collaboration',
  },
  {
    imgSrc: bannerSeven,
    path: 'https://digiliteracy.africa/',

    // title: 'Integrity',
  },
];

const Adsbanner = () => {
  return (
    <div className="rfm-marquee-container mt-24">

      <div className="rfm-marquee cursor-pointer">
        {slides.map((slide, index) => (
          <div className="rfm-child" key={index}>
            <a target='_blank' rel="noreferrer" href={slide.path} className="bg-white mr-4">
              <div className="relative h-full w-full">
                <img src={slide.imgSrc} alt={slide.title} className="slide-image" />
                {/* <div className="overlay">
                  <h4 className="title">{slide.title}</h4>
                </div> */}
              </div>
            </a>
          </div>
        ))}
        {/* Repeat slides for continuous effect */}
        {slides.map((slide, index) => (
          <div className="rfm-child" key={`duplicate-${index}`}>
            <div className="bg-white mr-4">
              <div className="relative h-full w-full">
                <img src={slide.imgSrc} alt={slide.title} className="slide-image" />
                {/* <div className="overlay">
                  <h4 className="title">{slide.title}</h4>
                </div> */}
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* <div class="rounded-[50%] absolute z-10 w-full h-[168px] md:h-[175px] bg-white -bottom-[3.2rem]"></div> */}
    </div>
  );
};

export default Adsbanner;
