import { Outlet } from 'react-router-dom'
// import useInactivityLogout from '../../components/dashboards/useInactivityLogout'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import DashNav from '../../components/DashNav'
import Sidebar from '../../components/Sidebar'
import { getApplication } from '../../Controllers/authSlice'


const DafaultDashboard = () => {
//   useInactivityLogout()

const dispatch = useDispatch()

useEffect(() => {

    dispatch(getApplication());
 
  }, [ dispatch])

  return (
 
<div className='w-full h-full bg-[#f9f9f9]'>



        <DashNav/>
        <div className='flex h-screen overflow-hidden'>
            <Sidebar />
            <div className='flex-1 overflow-y-auto md:ml-[260px] xl:ml-[300px] xl:p-4'>
                {/* <TopMenu /> */}
     {/* <TopMenu userBalance ={userBalance} totalFunding={totalBalance} refBalance={refBalance}/> */}

      
        <Outlet/>
      
     {/* <Footer/> */}

    </div>
    </div>
    </div>
  )
}

export default DafaultDashboard