import { useEffect } from "react";
import { bigImage, HeroBg, smallImage } from "../assets";
import Button from "./Button";
import Partners from "./Partners";
import Modal from "./Modal";
// import AOS from "aos"; // Import AOS

const Hero = () => {
 
  return (
    <div id="top" className="relative gap-5 py-10 md:pt-[96px] mt-0  ">
      <div className="absolute top-0 h-full w-full bg-cover bg-center" style={{ backgroundImage: `url(${HeroBg})` }} />
      
     <div className="px-5 md:px-[50px] xl:px-[100px] ">

     <div className="w-full px-5 pt-5 pb-10 kpl-[40px] xl:pl-[76px] md:pr-[30px] xl:pr-[60px] xl:py-6 md:pt-6 md:pb-20 xl:pb-14  gap-10 flex flex-col md:flex-row md:justify-between justify-center items-center border-[1px] border-solid rounded-[8px] border-[#D8D4D4]">
        <div className="flex w-full flex-col justify-center gap-7 md:justify-start items-start md:w-[50%]">
          <div className="flex flex-col md:justify-start justify-center text-left gap-3"
          
          
          data-aos="zoom-in-right"
          data-aos-delay="500"
    data-aos-duration="2000"
          >
            <h1 className="font-semibold text-2xl lg:text-4xl text-center md:text-left">
              Welcome to IDEAS Programme
            </h1>
            <p className="text-base leading-[19.2px] text-center md:text-left">
              We welcome applications from innovative Nigerians,  resident in Abuja, regardless of gender,
              disability, or any other background. We encourage applications from 
              females and individuals living with disabilities.
            </p>
          </div>

          <div className="flex justify-center md:justify-start w-full" data-aos="fade-up" 
           data-aos-delay="500"
    data-aos-duration="2000"
      >
            <Button to="/apply" text="Apply Now" />
          </div>
        </div>

        <div className="w-full px-5 md:w-[45%] h-auto" data-aos="zoom-in-left"
          data-aos-delay="500"
    data-aos-duration="2000">
          <img src={bigImage} className="hidden md:block" alt="Big Image" data-aos="zoom-in" />
          <img src={smallImage} className="md:hidden" alt="Small Image" data-aos="zoom-in" />
        </div>
      </div>

     </div>
      <div className="absolute w-full px-5 md:px-[80px] xl:px-[200px] bottom-4 md:-bottom-4 "
      //  data-aos="fade-up" 
        // data-aos-delay="200"
        // data-aos-duration="500"
      >
       <Partners />
      <Modal />

      </div>

    </div>
  );
};

export default Hero;
