import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { TbCircleDotted } from 'react-icons/tb';
import { ideas_logo, popupBG } from '../assets';
import { toast } from 'react-toast';
import { getApplication, login } from '../Controllers/authSlice';

const LoginPage = () => {
  
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errors, setErrors] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);  // Local loading state

  const navigate = useNavigate();
  const dispatch = useDispatch();
//   const { error } = useSelector((state) => state.auth);  // No need for Redux loading state here

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrors([]);
    setLoading(true); // Set local loading state to true
    try {
      const resultAction = await dispatch(login({ email, password }));
      const redirectRoute = localStorage.getItem('redirectPath');

      if (login.fulfilled.match(resultAction)) {
        const redirectPath = redirectRoute || '/dashboard';
        toast.success('Login Successful');
        dispatch(getApplication());

        navigate(redirectPath);

        localStorage.removeItem('redirectPath'); // Clear the redirect path after navigating
      } else {
        toast.error(resultAction.payload.message || resultAction.error.message);
        setErrors([resultAction.payload.message || resultAction.error.message]);
      }
    } catch (error) {
      toast.error(error.message);
      setErrors([error.message]);
    } finally {
      setLoading(false); // Set loading to false after the process completes
    }
  };


  return (
    <div className='loginBg px-8 md:px-40 lg:px-64 w-full bg-[#FFF3ED] pb-[1px] min-h-screen'>
          {/* <div className="absolute top-0  h-full w-full bg-cover bg-center rounded-r-lg" style={{ backgroundImage: `url(${popupBG})` }} /> */}
          <Link to="/" className='flex justify-center items-center pt-12'>
        <img src={ideas_logo} alt="Logo" />
      </Link>
    <div className='w-full flex items-center justify-center'>


    {/* <Link to="/" className='flex justify-center items-center pt-12'>
        <img src={ideas_logo} alt="Logo" />
      </Link> */}

      <div className=' py-[70px] px-[30px] mt-8 w-full lg:w-[360px] bg-white shadow-lg border-2 border-solid rounded-md flex flex-col items-center justify-center'>
        <form onSubmit={handleLogin} className='w-full'>
          <div className='w-full items-center flex flex-col justify-center'>
            <h1 className='text-xl font-semibold mb-6'>Sign In</h1>
            <div className='flex flex-col gap-4 w-full items-center'>
              <input
              id='email'
                type="text"
                name='email'
                placeholder='Enter email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black'
                required
              />
              <div className='relative w-full'>
                <input
                id='password'
                  type={passwordVisible ? 'text' : 'password'}
                  name='password'
                  placeholder='Enter Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black'
                  required
                />
                <div
                  onClick={togglePasswordVisibility}
                  className='absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-600'
                >
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>
            <div className='flex justify-between gap-3 items-center w-full mt-4'>
              <div className='flex gap-3 items-center'>
                <input type="checkbox" />
                <p className='text-nowrap text-xs lg:text-[16px]'>Remember Me</p>
              </div>
              <Link to='/forgot/password' className='text-[#03813E] text-xs lg:text-[16px] font-semibold text-nowrap'>
                Forgot Password?
              </Link>
            </div>
           
           
           <div className='flex flex-col gap-5 items-center justify-center w-full'>
              <div className='w-full flex flex-col items-center justify-center pt-6'>
                <button id='loginBtn'
                  type="submit"
                  className='py-2 px-8 bg-[#03813E] w-full rounded-xl h-[50px] text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300'
                  disabled={loading}  // Disable button when loading
                >
                  {loading ? (
                    <div className='flex items-center gap-1 font-semibold w-full justify-center'>
                      <TbCircleDotted className='animate-spin duration-200 text-xl font-extrabold' />
                      Authenticating...
                    </div>
                  ) : (
                    'Login'
                  )}
                </button>
                {/* <div className='flex items-center gap-2 mt-4'>
                  <p>Don't have an account?</p>
                  <Link to='/register' className='text-[#03813E] text-xs md:text-[16px] text-nowrap font-bold'>
                    Sign Up
                  </Link>
                </div> */}
              </div>
             
              
            </div>
          </div>
        </form>
        {/* {errors.length > 0 && (
          <div className='mt-4'>
            <ul className='text-red-500'>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )} */}
      </div>
    </div>
    </div>
  );
};

export default LoginPage;
