import  { useEffect, useState } from 'react';
import MiddleDash from './MiddleDash';
import PieChart from './PieChart';

const MainDash = ({   }) => {
 


  return (
    <div>




          <MiddleDash/>
       
       <PieChart />
      
    
    </div>
  );
};

export default MainDash;
