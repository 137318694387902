import React from 'react'
import { Link } from 'react-router-dom';
import { ideas_logo, phone_icon } from '../assets';
import { ContactUs, WebsiteLinks, companes, footerSocial, ideas, locations } from '../constants';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const [companyData] = companes;

    const [ContactData] = ContactUs;
    const [WebsiteLinksData] =WebsiteLinks;
  return (
    <div className='w-full border-t-2 border-solid mt-20 md:mt-16 h-11 pt-[27px] px-[20px] md:px-[50px] lg:px-[100px]  z-20'>
            
    <div className='xl:grid hidden grid-cols-12 lg:gap-[70px] md:gap-[20px] w-full justify-between'>
    <div className='col-span-3 flex flex-col gap-2'> 
        {
            ideas.map((item, index)=>(
                <div key={index} className='flex flex-col gap-3'>
                    


                    <Link to="/" className="text-xl font-bold">
                    <img src={item.img} alt="Logo" />

                </Link>

                    <p>{item.text}</p>
                </div>
            ))
        }

</div>



<div className='col-span-3 flex flex-col gap-2'>
      <h1 className="font-semibold text-[20px]">{ContactData.title}</h1>
      <div className='flex items-center gap-4'>
        <img src={phone_icon} alt="Phone" />
        <div>
        {ContactData.contacts.map((item, index) => (
          <div className='' key={index}>
            <p>{item.phone}</p>
          </div>
        ))}
      </div>
      </div>
    </div>




    <div className='col-span-3 flex flex-col gap-2'>
      <h1 className="font-semibold text-[20px]">{WebsiteLinksData.title}</h1>
      <div>
        {WebsiteLinksData.links.map((item, index) => (
          <div className='' key={index}>
            <Link to={item.path}>{item.label}</Link>
          </div>
        ))}
      </div>
    </div>
    

    <div className='col-span-3 flex flex-col gap-2'> 
        {
            locations.map((item, index)=>(
                <div key={index} className='flex flex-col gap-3'>
                    


                    <h1 className="font-semibold text-[20px]">
                    {item.title}
                </h1>

                    <p>{item.text}</p>
                </div>
            ))
        }

</div>

    </div>


{/* for tablets  */}


<div className='hidden md:flex  flex-col xl:hidden w-full h-auto pb-5'>
<div className='w-full flex  justify-between items-start  '>
<div className='flex flex-col items-start gap-3 w-1/3'>

{
            ideas.map((item, index)=>(
                <div key={index} className='flex flex-col gap-3'>
                    


                  
                    <Link to="/" className="text-xl font-bold">
                    <img src={item.img} alt="Logo" />

                </Link>
                    <p>{item.text}</p>
                </div>
            ))
        }
</div>


<div className='flex flex-col gap-3'>
<div>


<h1 className="font-semibold text-[20px]">{ContactData.title}</h1>
      <div className='flex items-center gap-4'>
        <img src={phone_icon} alt="Phone" />
        <div>
        {ContactData.contacts.map((item, index) => (
          <div className='' key={index}>
            <p>{item.phone}</p>
          </div>
        ))}
      </div>
      </div>
</div>

</div>

<div className='flex flex-col gap-3'>

                <div>

<h1 className="font-semibold text-[20px]">{WebsiteLinksData.title}</h1>
      <div>
        {WebsiteLinksData.links.map((item, index) => (
          <div className='' key={index}>
            <Link to={item.path}>{item.label}</Link>
          </div>
        ))}
      </div>

</div>


</div>
  </div>



  <div className='w-full flex justify-between items-start my-[22px] '>

                
                <div className='flex flex-col gap-3'>
                <div className='w-1/2'>


{
            locations.map((item, index)=>(
                <div key={index} className='flex flex-col gap-3'>
                    


                    <h1 className="font-semibold text-[20px]">
                    {item.title}
                </h1>

                    <p>{item.text}</p>
                </div>
            ))
        }


</div>


</div>



</div>
  </div>

{/* mobile  */}

    <div className='w-full block md:hidden h-auto '>

      <div className='w-full flex flex-col gap-5'>
      <div className='flex flex-col items-start gap-3'>

{
            ideas.map((item, index)=>(
                <div key={index} className='flex flex-col gap-3'>
                    


                   
                    <Link to="/" className="text-xl font-bold">
                    <img src={ideas_logo} alt="Logo"  />


                </Link>

                    <p>{item.text}</p>
                </div>
            ))
        }

</div>



<div>


<h1 className="font-semibold text-[20px]">{ContactData.title}</h1>
      <div className='flex items-center gap-4'>
        <img src={phone_icon} alt="Phone" />
        <div>
        {ContactData.contacts.map((item, index) => (
          <div className='' key={index}>
            <p>{item.phone}</p>
          </div>
        ))}
      </div>
      </div>
</div>


<div>

<h1 className="font-semibold text-[20px]">{WebsiteLinksData.title}</h1>
      <div>
        {WebsiteLinksData.links.map((item, index) => (
          <div className='' key={index}>
            <Link to={item.path}>{item.label}</Link>
          </div>
        ))}
      </div>

</div>


<div className='w-full'>


{
            locations.map((item, index)=>(
                <div key={index} className='flex flex-col gap-3'>
                    


                    <h1 className="font-semibold text-[20px]">
                    {item.title}
                </h1>

                    <p>{item.text}</p>
                </div>
            ))
        }


</div>



{/* </div> */}


      </div>





</div>
    





<div data-aos="fade-up"
          data-aos-delay="100"
    data-aos-duration="1000"
     className='w-full flex justify-center md:justify-start gap-5 py-6'>
  {
    footerSocial.map((item, index)=>(
      <div>
        <img src={item.img} alt={`Image-${index}`} />
<a href={item.path} target='_blank'>  </a>
      </div>
    ))
  }
</div>

    <div className='w-full border-t-2 flex-col md:flex-row border-solid mt-1 pb-5 flex justify-between items-center'>

        <div className='flex justify-center items-center '>
        © {currentYear}  IDEAS Programme. All rights reserved. 
        </div>


    

      <div className='flex items-center gap-4'>
       <>
       {companyData.links.map((item, index) => (
          <div  key={index}>
            <a href="#top">{item.label}</a>
          </div>
        ))}
       </>
       <div className='h-full bg-red-500 w-1 border-l-2 border-solid' />
      </div>

    </div>

  


    </div>
  )
}

export default Footer