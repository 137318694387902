import { useEffect, useState } from 'react';
import Select from 'react-select';
import axiosClient from '../axiosClient';
import axios from 'axios';

export const FirstFormPage = ({ handleChange, formData }) => {
  const [error, setError] = useState({});
  const [stateOptions, setStateOptions] = useState([]);
  const [localGovtOptions, setLocalGovtOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedLocalGovt, setSelectedLocalGovt] = useState(null);


  // Define the options here
  const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    // { value: 'other', label: 'Other' },
  ];

  const maritalOptions = [
    { value: 'single', label: 'Single' },
    { value: 'married', label: 'Married' },
    { value: 'divorced', label: 'Divorced' },
    { value: 'widowed', label: 'Widowed' },
  ];

  const qualificationOptions = [
    { value: 'No Formal Education', label: 'No Formal Education' },
    { value: 'Primary School', label: 'Primary School' },
    { value: 'Secondary School', label: 'Secondary School' },
    { value: 'Bachelor', label: 'Bachelor’s Degree' },
    { value: 'Master', label: 'Master’s Degree' },
    { value: 'Doctorate', label: 'Doctorate' },
  ];

  const governementIDOptions = [
    { value: 'passport', label: 'Passport' },
    { value: 'driverlicense', label: 'Driver License' },
    { value: 'nationalid', label: 'National ID' },
    { value: 'voterscard', label: 'Voter’s Card' },
  ];

  const communite_center = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" }
  ];



  const fctResidence = [
    { label: "Yes", value: 'Yes' },
    { label: "No", value: 'No' }
    
  ]
  const countryId = 161; // Replace with the actual country ID or a variable if dynamic

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axiosClient.get(`user/ideas-programme/countries/${countryId}/states`);
  //       const options = response.data.data.map((state, index) =>
  //          ({
  //         label: state.name,
  //         value: state.name,
  //       }));
  //       setStateOptions(options);
  //     } catch (err) {
  //       setError({ ...error, fetch: "Error fetching states" });
  //       console.error("Error fetching states:", err);
  //     }
  //   };
  //   fetchData();
  // }, [countryId]);

  const isResidentOfAbuja = formData.state_of_residence === 'Yes'; // Assuming 'abuja' is the value for Abuja


  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await axiosClient.get(`user/ideas-programme/countries/${countryId}/states`);
        const response = await axios.get('https://nigeria-states-towns-lgas.onrender.com/api/states');
        // console.log('response', response.data)
        const options = response.data.map((state) => ({
          label: state.name,
          value: state.name,
          state_code: state.state_code, // Using index as id
        }));
        setStateOptions(options);
      } catch (err) {
        setError({ ...error, fetch: "Error fetching states" });
        console.error("Error fetching states:", err);
      }
    };
    fetchData();
  }, [countryId]);
  

  


  useEffect(() => {

      const fetchLocalGovernments = async () => {
        try {
          
          const response = await axios.get('https://nigeria-states-towns-lgas.onrender.com/api/FCT/lgas');
          // console.log('responsem lga ', response.data)
          const options = response.data.map(city => ({
            value: city.name, 
            label: city.name, 
          }));
          setLocalGovtOptions(options);
        } catch (err) {
          setError(prevError => ({
            ...prevError,
            fetchLocalGovt: "Error fetching local governments"
          }));
          console.error('Error fetching local governments:', err);
        }
      };
  
      fetchLocalGovernments();
    
  }, []); // Add stateOptions to dependency array
  


 

  // console.log('selected sate is ', selectedState.label , 'and selected local governmet is  ', selectedLocalGovt.label)

  const handleSelectChange = (name) => (selectedOption) => {
    handleChange(name, selectedOption ? selectedOption.value : '');
    if (name === 'state_of_residence') {
      setSelectedState(selectedOption);
    }
    if (name === 'lga_of_residence') {
      setSelectedLocalGovt(selectedOption);
    }
  };

  useEffect(() => {
    let newError = {};
    if (formData.phone_number !== formData.confirm_phone_number) {
      newError.phone = "Phone numbers don't match";
    }
    if (formData.email !== formData.confirm_email) {
      newError.email = "Emails don't match";
    }
    setError(newError);
  }, [formData.phone_number, formData.confirm_phone_number, formData.email, formData.confirm_email]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: 'transparent',
      border: state.isFocused ? '0.78px solid #ffff' : '0.78px solid #ffff',
      height: '48px',
      boxShadow: state.isFocused ? '0 0 0 0.78px #03813E' : 'none',
      '&:hover': {
        border: '0.78px solid #03813E',
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: '#000', // Change the selected value color
    }),
    placeholder: (base) => ({
      ...base,
      color: '#575252', // Change the placeholder color
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: '#fff', // Change the dropdown arrow color
      '&:hover': {
        color: '#fff', // Change the arrow color on hover
      },
    }),
    indicatorSeparator: () => ({
      display: 'none', // Remove the vertical separator line
    }),
  };
  
  return (
    <>
      <div className="flex items-center gap-0 justify-between py-3">
        <div className="flex items-center justify-center text-white font-semibold rounded-full px-4 w-[70px] text-4xl h-[70px] bg-[#03813E]">1</div>
        <div className="border-[#CACACA] bg-[#CACACA] w-full h-[10px]" />

        <div className="flex items-center justify-center  font-semibold rounded-full w-[70px] px-4 text-white text-4xl h-[70px] bg-transparent border-[2px] border-solid border-[#03813E]">2</div>
      </div>

      <div className="flex flex-col md:flex-row items-center mb-4 gap-4 md:mb-[32px] justify-between w-full   md:gap-10 lg:gap-[88px]">
        <div className="w-full ">
          <label htmlFor="firstName" className="text-base text-white ">First Name <span className="text-red-600 pb-2">*</span></label>
          <input
            type="text"
            value={formData.first_name}
            onChange={(e) => handleChange('first_name', e.target.value)}
            required
            placeholder="First Name"
            className="w-full bg-transparent pl-3 placeholder:text-[#575252] focus:border-[#03813E] focus:outline-none placeholder:text-base h-[48px] rounded-md border-solid border-[0.78px] border-white"
          />
        </div>
                <div className="w-full">

          <label htmlFor="surname" className="text-base text-white py-[6px]">Surname <span className="text-red-600 pb-2">*</span></label>
          <input
            type="text"
            value={formData.last_name}
            onChange={(e) => handleChange('last_name', e.target.value)}
            required
            placeholder="Last Name"
            className="w-full pl-3 bg-transparent placeholder:text-[#575252] focus:border-[#03813E] focus:outline-none placeholder:text-base h-[48px] rounded-md border-solid border-[0.78px] border-white"
          />
        </div>
      </div>

      
      
      
      
      
      
      <div className="flex flex-col md:flex-row items-center md:mb-[32px] justify-between w-full mb-4 gap-4  md:gap-10 lg:gap-[88px]">
                <div className="w-full ">

          <label htmlFor="dob" className="text-base text-white py-[6px]">Date of Birth <span className="text-red-600 pb-2">*</span></label>
          <input
            type="date"
            value={formData.date_of_birth}
            onChange={(e) => handleChange('date_of_birth', e.target.value)}
            required
            className="w-full pl-3 bg-transparent uppercase focus:border-[#03813E] focus:outline-none  text-[#575252] text-sm h-[48px] rounded-md border-solid border-[0.78px] border-white"
            style={{ color: '#575252' }}
          />
        </div>


{/* <div className="w-full relative">
  <label htmlFor="dob" className="text-base text-white py-[6px]">
    Date of Birth <span className="text-red-600 pb-2">*</span>
  </label>

  <input
    type="date"
    value={formData.date_of_birth}
    onChange={(e) => handleChange('date_of_birth', e.target.value)}
    required
    className="w-full pl-3 pr-10 md:pr-0 bg-transparent uppercase focus:border-[#03813E] focus:outline-none text-[#575252] text-sm h-[48px] rounded-md border-solid border-[0.78px] border-white"
    style={{ color: '#575252' }}
  />

  <div className="absolute right-3 top-9 md:hidden">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="white"
      width="24px"
      height="24px"
      className="pointer-events-none" 
    >
      <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.1 0-1.99.9-1.99 2L3 20c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM19 20H5V9h14v11zM7 11h5v5H7z" />
    </svg>
  </div>
</div> */}

        
                <div className="w-full ">

          <label htmlFor="gender" className="text-base text-white py-[6px]">Gender <span className="text-red-600 pb-2">*</span></label>
          <Select
            options={genderOptions}
            styles={customStyles}
            placeholder="Choose Gender"
            className='rounded-md'
            onChange={handleSelectChange('gender')}
            value={genderOptions.find(option => option.value === formData.gender)}
            required
          />
        </div>
      </div>

     <div className="flex flex-col md:flex-row items-center md:mb-[32px] justify-between w-full mb-4 gap-4  md:gap-10 lg:gap-[88px]">
                <div className="w-full">

          <label htmlFor="maritalStatus" className="text-base text-white py-[6px]">Marital Status <span className="text-red-600 pb-2">*</span></label>
          <Select
            options={maritalOptions}
            styles={customStyles}
            placeholder="Choose Marital Status"
            onChange={handleSelectChange('marital_status')}
            value={maritalOptions.find(option => option.value === formData.marital_status)}
            className='rounded-md'
            required
          />
        </div>
             <div className="w-full">

          <label htmlFor="qualification" className="text-base text-white py-[6px]">Highest Qualification <span className="text-red-600 pb-2">*</span></label>
          <Select
            options={qualificationOptions}
            styles={customStyles}
            onChange={handleSelectChange('highest_qualification')}
            value={qualificationOptions.find(option => option.value === formData.highest_qualification)}
            placeholder="Choose"
            className='rounded-md'
            required
          />
        </div>
      </div>

     <div className="flex flex-col md:flex-row items-center md:mb-[32px] justify-between w-full mb-4 gap-4  md:gap-10 lg:gap-[88px]">
             <div className="w-full">

          <label htmlFor="email" className="text-base text-white py-[6px]">Email <span className="text-red-600 pb-2">*</span></label>
          <input
            type="email"
            value={formData.email}
            onChange={(e) => handleChange('email', e.target.value)}
            required
            placeholder="example@example.com"
            className="w-full bg-transparent pl-3 placeholder:text-[#575252] focus:border-[#03813E] focus:outline-none placeholder:text-base h-[48px] rounded-md border-solid border-[0.78px] border-white"
          />
          {error.email && <p className="text-red-500">{error.email}</p>}
        </div>
             <div className="w-full">

          <label htmlFor="confirmEmail" className="text-base text-white py-[6px]">Confirm Email <span className="text-red-600 pb-2">*</span></label>
          <input
            type="email"
            value={formData.confirm_email}
            onChange={(e) => handleChange('confirm_email', e.target.value)}
            required
            placeholder="example@example.com"
            className="w-full bg-transparent pl-3 placeholder:text-[#575252] focus:border-[#03813E] focus:outline-none placeholder:text-base h-[48px] rounded-md border-solid border-[0.78px] border-white"
          />
          {error.email && <p className="text-red-500">{error.email}</p>}
        </div>
      </div>

     <div className="flex flex-col md:flex-row items-center md:mb-[32px] justify-between w-full mb-4 gap-4  md:gap-10 lg:gap-[88px]">
             <div className="w-full">

          <label htmlFor="phone" className="text-base text-white py-[6px]">Phone Number (WhatsApp) <span className="text-red-600 pb-2">*</span></label>
          <input
            type="text"
            value={formData.phone_number}
            onChange={(e) => handleChange('phone_number', e.target.value)}
            required
            placeholder="07034232132"
            maxLength="11"
            className="w-full bg-transparent pl-3 placeholder:text-[#575252] focus:border-[#03813E] focus:outline-none placeholder:text-base h-[48px] rounded-md border-solid border-[0.78px] border-white"
          />
          {error.phone && <p className="text-red-500">{error.phone}</p>}
        </div>
             <div className="w-full">

          <label htmlFor="confirmPhone" className="text-base text-white py-[6px]">Confirm Phone Number (WhatsApp) <span className="text-red-600 pb-2">*</span></label>
          <input
            type="text"
            value={formData.confirm_phone_number}
            onChange={(e) => handleChange('confirm_phone_number', e.target.value)}
            required
            placeholder="07034232132"
            maxLength="11"
            className="w-full bg-transparent pl-3 placeholder:text-[#575252] focus:border-[#03813E] focus:outline-none placeholder:text-base h-[48px] rounded-md border-solid border-[0.78px] border-white"
          />
          {error.phone && <p className="text-red-500">{error.phone}</p>}
        </div>
      </div>

     <div className="flex flex-col md:flex-row items-center md:mb-[32px] justify-between w-full mb-4 gap-4  md:gap-10 lg:gap-[88px]">
             <div className="w-full">

          <label htmlFor="linkedin" className="text-base text-white py-[6px]">Linkedin ID (if any)</label>
          <input
            type="text"
            value={formData.linkedin_id}
            onChange={(e) => handleChange('linkedin_id', e.target.value)}
            placeholder="Linkedin ID"
            className="w-full pl-3 bg-transparent placeholder:text-[#575252] focus:border-[#03813E] focus:outline-none placeholder:text-base h-[48px] rounded-md border-solid border-[0.78px] border-white"
          />
        </div>
             <div className="w-full">

          <label htmlFor="facebook" className="text-base text-white py-[6px]">Facebook ID (if any)</label>
          <input
            type="text"
            value={formData.facebook_id}
            onChange={(e) => handleChange('facebook_id', e.target.value)}
            placeholder="Facebook ID"
            className="w-full pl-3 bg-transparent placeholder:text-[#575252] focus:border-[#03813E] focus:outline-none placeholder:text-base h-[48px] rounded-md border-solid border-[0.78px] border-white"
          />
        </div>
      </div>

     <div className="flex flex-col md:flex-row items-center md:mb-[32px] justify-between w-full mb-4 gap-4  md:gap-10 lg:gap-[88px]">
             <div className="w-full">

          <label htmlFor="twitter" className="text-base text-white py-[6px]">X ID (Twitter if any)</label>
          <input
            type="text"
            value={formData.x_id}
            onChange={(e) => handleChange('x_id', e.target.value)}
            placeholder="X ID (Twitter)"
            className="w-full pl-3 bg-transparent placeholder:text-[#575252] focus:border-[#03813E] focus:outline-none placeholder:text-base h-[48px] rounded-md border-solid border-[0.78px] border-white"
          />
        </div>

       
            

        <div className="w-full ">

<label htmlFor="govID" className="text-base text-white py-[6px]"> Can you afford commute to your training center at Mabushi? <span className="text-red-600 pb-2">*</span></label>

<Select
  options={communite_center}
  styles={customStyles}
  onChange={handleSelectChange('commute_affordability')}
  value={communite_center.find(option => option.value === formData.commute_affordability)}
  placeholder="Choose"
  className='rounded-md'
  required
/>
</div>
      </div>

      <div className="flex flex-col md:flex-row items-center md:mb-[32px] justify-between w-full mb-4 gap-4  md:gap-10 lg:gap-[88px]">
             <div className="w-full">

          <label htmlFor="state" className="text-base text-white py-[6px]">Are you a residence of Abuja?<span className="text-red-600 pb-2">*</span></label>
          <Select
            options={fctResidence}
            styles={customStyles}
            placeholder="Choose"
            className='rounded-md'
            onChange={handleSelectChange('state_of_residence')}
            // value={selectedState ? { value: selectedState.label, label: selectedState.label } : null}
            value={fctResidence.find(option => option.value === formData.state_of_residence) || null} // Use formData.state_of_residence for comparison
          />
        </div>
        
        
        {/* <div className="w-full">
          <label htmlFor="localGovt" className="text-base text-white py-[6px]">LGA <span className="text-red-600 pb-2">*</span></label>
          <Select
            options={localGovtOptions}
            styles={customStyles}
            placeholder="Choose"
            className='rounded-md'
            onChange={handleSelectChange('lga_of_residence')}
            value={selectedLocalGovt ? { value: selectedLocalGovt.label, label: selectedLocalGovt.label } : null}
          />
        </div> */}

{isResidentOfAbuja && ( // Conditionally render the LGA select
        <div className="w-full">
          <label htmlFor="localGovt" className="text-base text-white py-[6px]">
            LGA <span className="text-red-600 pb-2">*</span>
          </label>
          <Select
            options={localGovtOptions}
            styles={customStyles}
            placeholder="Choose"
            className='rounded-md'
            onChange={handleSelectChange('lga_of_residence')}
            value={localGovtOptions.find(option => option.value === formData.lga_of_residence) || null}
          />
        </div>
      )}


      </div>  
    </>
  );
};
