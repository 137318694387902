import { line_one, line_two } from "../assets"


const HeaderLine = ({text}) => {
  return (
    <div className='w-full h-auto py-1 md:py-3 px-[24px] md:px-[100px] xl:px-[358px] '>
        



        <div className='w-full  h-auto flex items-center justify-center gap-5 pt-12 md:pt-[95px] '>
        <img src={line_one} className='w-[60px] md:w-[150px] lg:w-full ' alt="Left Line" />
        <h2 className='text-[18px] md:text-[30px] font-[700] leading-[24px] md:leading-[49px] whitespace-nowrap'>{text}</h2>
        <img src={line_two} className='w-[60px] md:w-[150px] lg:w-full ' alt="Right Line" />
      </div>
      
    </div>
  )
}

export default HeaderLine