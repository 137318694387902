import {  partners } from '../constants'
import HeaderLine from './HeaderLine'

const Partners= () => {
  return (

<div
// data-aos="fade-up"
// data-aos-delay="100"
// data-aos-duration="2000"
className='flex justify-center items-center gap-2 md:gap-9 '>
{/* className='grid w-full  grid-cols-2 lg:grid-cols-3 gap-5'> */}


{partners.map((item, index) => (
          <div key={index} className='flex jus8tify-center shadow-lg w-[100px]  md:w-[250px] h-[50px] md:h-[120px] items-center bg-white px-5  xl:px-[45px] py-5  md:py-[18px]  border-[1px] rounded-lg border-[#03813E]'>
            <div className='relative w-full h-32 md:h-40 lg:h-48'>
              <img 
                src={item.img} 
                alt={`Image-${index}`} 
                className='absolute hidden md:block inset-0 object-contain w-full h-full' 
              />
              <img 
                src={item.img2} 
                alt={`Image-${index}`} 
                className='absolute md:hidden inset-0 object-contain w-full h-full' 
              />
            </div>
          </div>
        ))}


</div>




  )
}

export default Partners