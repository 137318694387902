import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../contexts/context';
import LogoutPopup from './LogoutPopup';
import { sidebarItems } from '../constants';

const Sidebar = () => {
  const { state, dispatch } = useContext(Context);
  const [activeTab, setActiveTab] = useState(null);
  const [logoutPopup, setLogoutPopup] = useState(false);

  const handlePopup = () => {
    setLogoutPopup(true);
  };

  const closePopup = () => {
    setLogoutPopup(false);
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
    // Collapse the sidebar if the device width is small
    if (window.innerWidth < 768) {
      dispatch({ type: 'SET_TOGGLE_SIDEBAR', payload: false });
    }
  };

  // Cleanup the resize event listener
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        dispatch({ type: 'SET_TOGGLE_SIDEBAR', payload: true });
      }
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]);

  
  return (
    

    <div>

<div
    className={`fixed top-0 left-0 h-full w-[300px] md:w-[250px] xl:w-[300px] pt-[45px] bg-[#03813E] z-30 sidebar ${
      state.toggleSidebar ? 'sidebar-visible' : 'sidebar-hidden'
    } md:translate-x-0 md:block`}
  >



      {/* <div className='flex flex-col      gap-5 h-full overflow-auto pt-[100px] pb-10'>
        {sidebarItems.map((item, index) => (
          <div
            key={index}
            onClick={() => handleTabClick(index)}
            className={`flex items-center gap-4 cursor-pointer  ${activeTab === index ? 'bg-white rounded-r-3xl transition-all duration-1000 ease-out w-[260px] py-1 pl-16 md:pl-8 xl:pl-16 text-[#03813E]  h-[100px]' : 'text-white  pl-16 md:pl-8 xl:pl-16'}`}
          > 
         <Link
  to={item.path}
  className={`w-[53px] text-[36px] ${activeTab === index ? 'bg-[#03813E] text-white' : 'bg-white text-[#03813E]'} h-[53px] shadow-md flex items-center justify-center rounded-[11px] hover:bg-[#f3f0ee] duration-300 ease-in`}
  onClick={() => {
    if (index === 9) {
      handlePopup();
    }
  }}
>
              {item.icon}
            </Link>
            <p className='text-[16px] font-bold whitespace-nowrap '>{item.label}</p>
          </div>
        ))}
      </div>  */}



<div className='flex flex-col gap-5 h-full overflow-auto pt-[100px] pb-10'>
  {sidebarItems.map((item, index) => (
    <div
      key={index}
      onClick={() => handleTabClick(index)}
      className={`flex items-center gap-4 cursor-pointer ${activeTab === index ? 'bg-white rounded-r-3xl transition-all duration-1000 ease-out w-[260px] py-6 pl-16 md:pl-8 xl:pl-16 text-[#03813E] h-[60px]' : 'text-white pl-16 md:pl-8 xl:pl-16'}`}
    >
      <Link
        to={item.path}
        className={`w-[53px] text-[36px] ${activeTab === index ? 'bg-[#03813E] text-white' : 'bg-white text-[#03813E]'} h-[53px] shadow-md flex items-center justify-center rounded-[11px] hover:bg-[#f3f0ee] duration-300 ease-in`}
        onClick={() => {
          if (index === 2) {
            handlePopup();
          }
        }}
        
      >
        {item.icon}
      </Link>
      <Link
        to={item.path}
        className='text-[16px] font-bold whitespace-nowrap'
        onClick={() => {
          if (index === 2) {
            handlePopup();
          }
        }}
      >
        {item.label}
      </Link>
    </div>
  ))}
</div>





{/* <div className='flex flex-col gap-5 h-full overflow-auto pt-[100px] pb-10'>
  {sidebarItems.map((item, index) => (
    <Link
      key={index}
      to={item.path}
      className={`flex items-center gap-4 cursor-pointer text-white pl-16 md:pl-8 xl:pl-16`}
      onClick={() => {
        if (index === 9) {
          handlePopup();
        }
      }}
    >
      <div
     
  className={`w-[53px] text-[36px] ${activeTab === index ? 'bg-[#03813E] text-white' : 'bg-white text-[#03813E]'} h-[53px] shadow-md flex items-center justify-center rounded-[11px] hover:bg-[#f3f0ee] duration-300 ease-in`}
     
     >
        {item.icon}
      </div>
      <p className='text-[16px] font-bold whitespace-nowrap'>{item.label}</p>
    </Link>
  ))}
</div> */}



{/* <div className='flex flex-col gap-5 h-full overflow-auto pt-[100px] pb-10'>
      {sidebarItems.map((item, index) => (
        <Link
          key={index}
          to={item.path}
          className={`w-[53px] text-[36px] ${activeTab === index ? 'bg-[#03813E] text-white' : 'bg-white text-[#03813E]'} h-[53px] shadow-md flex items-center justify-center rounded-[11px] hover:bg-[#f3f0ee] duration-300 ease-in`}

          onClick={() => {
            setActiveTab(index);
            if (index === 9) {
              handlePopup();
            }
          }}
        >
          <div
            className={`w-[53px] text-[36px] ${
              activeTab === index
                ? 'bg-[#03813E]  text-white'
                : 'bg-white text-[#03813E]'
            } h-[53px] shadow-md flex items-center justify-center rounded-[11px] hover:bg-[#f3f0ee] duration-300 ease-in`}
          >
            {item.icon}
          </div>
          <p className='text-[16px] font-bold whitespace-nowrap'>
            {item.label}
          </p>
        </Link>
      ))}
    </div> */}


      
    </div>

    {
        logoutPopup && (
          <LogoutPopup isVisible={logoutPopup} onClose={closePopup}/>
        )
      }
    </div>
  );
};

export default Sidebar;
