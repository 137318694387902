import  { useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { useSelector } from 'react-redux';



const statuses = {
  Approved: 'bg-green-100 text-green-700',
  Pending: 'bg-yellow-100 text-yellow-700',
  Declined: 'bg-red-100 text-red-700',
};

const ApplicationContent = () => {
  const {applications} = useSelector((state)=>state.auth);
  const [activeTab, setActiveTab] = useState('ALL'); // Tracks active tab

  console.log('applications are ', applications);
  // Function to filter applications based on status
  const filterapplications = (status) => {
    if (status === 'ALL') return applications;
    return applications?.filter((app) => app.status === status);
  };

  return (
    <div className="p-4">
      {/* Tab Navigation */}
      <div className="flex space-x-4 mb-4">
        {['ALL', 'Active', 'Inactive', 'Removed'].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`py-2 px-4 ${activeTab === tab ? 'border-b-4 border-blue-500 text-blue-500' : 'text-gray-600'}`}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="p-2 border border-gray-300">Image</th>
              <th className="p-2 border border-gray-300">Applicant Name</th>
              <th className="p-2 border border-gray-300">Email Address</th>
              <th className="p-2 border border-gray-300">Phone No.</th>
              {/* <th className="p-2 border border-gray-300">app I.D</th> */}
              <th className="p-2 border border-gray-300">Highest Qualifications</th>
              <th className="p-2 border border-gray-300">Sex</th>
              <th className="p-2 border border-gray-300">Status</th>
              <th className="p-2 border border-gray-300">Action</th>
            </tr>
          </thead>
          <tbody>
            {filterapplications(activeTab).map((app, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="p-2 border border-gray-300">
                  <img src={app.national_id_img_url} alt="app" className="w-10 h-10 rounded-full" />
                </td>
                <td className="p-2 border border-gray-300">{app.first_name} {app.last_name}</td>
                <td className="p-2 border border-gray-300">{app.email}</td>
                <td className="p-2 border border-gray-300">{app.phone_number}</td>
                {/* <td className="p-2 border border-gray-300">{app.appID}</td> */}
                <td className="p-2 border border-gray-300">{app.highest_qualification}</td>
                <td className="p-2 border border-gray-300">{app.gender}</td>
                <td className="p-2 border border-gray-300">
                  <span
                    className={`py-1 px-3 rounded-full text-xs font-semibold ${statuses[app.application_status]}`}
                  >
                    {app.application_status
                    }
                  </span>
                </td>
                <td className="p-2 border border-gray-300 text-center">
                  <button>
                    {/* <FiMoreVertical /> */}

                    view
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ApplicationContent;
