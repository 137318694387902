import { ring_one, ring_two } from "../assets"


const Rectangle = () => {
  return (
    <div className="bg-[#50B57F] flex items-center justify-center px-[30px] xl:px-[200px] h-[80px] w-full relative text-sm md:text-2xl mt-24">
        <img className="absolute top-0 left-[40px] xl:left-[258px]" src={ring_two} alt="Ring" />
      <p className="text-white leading-[16px] md:leading-[25px] text-center">Unlock your potential and gain in-demand skills with our expert-led courses</p>
        <img className="absolute bottom-0 right-[40px] xl:right-[258px]" src={ring_one} alt="Ring" />
    </div>
  )
}

export default Rectangle
