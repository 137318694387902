import Hero from '../components/Hero'
import Courses from '../components/Courses'
import Adsbanner from '../components/Adsbanner'
import Rectangle from '../components/Rectangle'

const HomePage = () => {
  return (
    <div>
<div className='w-full '>

<Hero/>

</div>
<Rectangle />

<Adsbanner />

<Courses/>
    </div>
  )
}

export default HomePage