import { mainDashitems } from "../constants"
import Button from "./Button"


const MiddleDash = () => {
  return (
    <div className="mt-40 w-full flex justify-between items-start flex-col md:flex-row bg-white py-10 px-6 rounded-xl">
      <div className=" grid grid-cols-1 gap-5 md:grid-cols-2 w-full">
        {
            mainDashitems.map((item, index)=>(

                <div key={index} className="flex pl-[18px] pt-[18px] rounded-lg gap-6 shadow-md w-[315px] h-[130px]">
                    <img src={item.img} className="w-[40px] h-[40px]" alt={`Image-${item.label}`} />
                    <div className="flex flex-col gap-1 ">
                        <h1>{item.label}</h1>
                        <p>{item.number}</p>
                    </div>
                </div>
            ))
        }
      </div>
      <div>
        <Button text="Close Application" />
      </div>
    </div>
  )
}

export default MiddleDash
