import { useEffect, useState } from 'react';
import { courses } from '../constants'
import HeaderLine from './HeaderLine'
import axiosClient from '../axiosClient';

const Courses = () => {

  const [courseOptions, setCourseOptions] = useState([]);
  const [error, setError] = useState(null);


  
  useEffect(() => {
    const fetchCourse = async () => {
      try {
        // const response = await axiosClient.get(`user/ideas-programme/countries/${countryId}/states`);
        const response = await axiosClient.get('user/ideas-programme/all_digital_skills_track');
        console.log('response is course', response.data.data)
        const options = response.data.data?.map((state) => ({
          title: state.name,
          img: state.image_url,
          
         
        }));
        setCourseOptions(options);
      } catch (err) {
        setError({ ...error, fetch: "Error fetching states" });
        console.error("Error fetching states:", err);
      }
    };
    fetchCourse();
  }, []);
  



  return (
    <div className='w-full  h-auto px-5 md:px-2 bg-[#F6F4F4] pb-[98px] lg:px-[100px] '
    
    data-aos="fade-zoom-in"
     data-aos-easing="ease-in-back"
     data-aos-delay="300"
     data-aos-offset="0">
 
 <div data-aos="fade-right"
 data-aos-delay="200"
 data-aos-duration="2000">
<HeaderLine text="Our Courses"  />

 </div>


    
 
<div
data-aos="fade-up"
data-aos-delay="100"
data-aos-duration="1000"
className='grid w-full  grid-cols-2 lg:grid-cols-3 gap-5 '>

{
    courseOptions.map((item, index)=>(
        <div key={index} className='flex shadow-sm flex-col w-full justify-center gap-5 items-center border-solid border-2 py-5 border-[#03813E] rounded-lg px-[1px] bg-white'>
            <img src={item.img} alt={`circle-${index}`} />
            <p className='text-base lg:text-2xl text-center'>{item.title}</p>
        </div>
    ))
}


</div>



    </div>
  )
}

export default Courses