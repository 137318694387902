import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RouterProvider } from 'react-router-dom';
import { router } from './route';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './Controllers/store';
import { ContextProvider } from './contexts/context';
import { ToastContainer } from 'react-toast';
import "aos/dist/aos.css";
// import 'react-toastify/dist/ReactToastify.css';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>

      <ToastContainer theme='light'/>
    <ContextProvider>
    <RouterProvider router={router}>
    <App />

    </RouterProvider>
    </ContextProvider>
    
    </PersistGate>
</Provider>
  </React.StrictMode>
);


