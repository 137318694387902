import { useEffect, useState } from 'react';
import Select from 'react-select';
import axiosClient from '../axiosClient';
import axios from 'axios';

export const ThirdFormPage = ({ handleChange, formData }) => {
  const [error, setError] = useState({});
  const [stateOptions, setStateOptions] = useState([]);
  const [localGovtOptions, setLocalGovtOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedLocalGovt, setSelectedLocalGovt] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  // Define the options here
  const certificateCompletionOptions = [
    { value: 'In Progress', label: 'In Progress' },
    { value: 'Completed', label: 'Completed' },
  ];
  const sourceOptions = [
    { label: "Facebook", value: "Facebook" },
    { label: "Instagram", value: "Instagram" },
    { label: "WhatsApp", value: "WhatsApp" },
    { label: "Radio", value: "Radio" },
    { label: "Newspaper", value: "Newspaper" },
    { label: "Email Newsletter", value: "Email Newsletter" },
    { label: "Google", value: "Google" },
    { label: "LinkedIn", value: "LinkedIn" },
    { label: "TV", value: "TV" },
    { label: "Our Website", value: "Our Website" },
    { label: "Others (specify)", value: "Others (specify)" },
  ];
 

  const aimOfTraining = [
    { label: "Skills acquisition", value: "Skills acquisition" },
    { label: "Self-development", value: "Self-development" },
    { label: "Employment", value: "Employment" }
  ];

  const devices= [
    { value: 'Laptop', label: 'Laptop' },
    { value: 'Smart Phone', label: 'Smart Phone' },
    { value: 'Tablet', label: 'Tablet' },
    
  ];
  const ageOptions = [
    { value: '0-17', label: '0-17' },
    { value: '18-30', label: '18-30' },
    { value: '31-45', label: '31-45' },
    { value: '46-60', label: '46-60' },
    // { value: 'other', label: 'Other' },
  ];
  const professionCert = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
    
    
  ];

  const maritalOptions = [
    { value: 'single', label: 'Single' },
    { value: 'married', label: 'Married' },
    { value: 'divorced', label: 'Divorced' },
    { value: 'widowed', label: 'Widowed' },
  ];

  const qualificationOptions = [
    { value: 'highschool', label: 'High School' },
    { value: 'bachelor', label: 'Bachelor’s Degree' },
    { value: 'master', label: 'Master’s Degree' },
    { value: 'doctorate', label: 'Doctorate' },
  ];




  const handleImageChange = event => {
    if (!event || !event.target) {
      console.error('Event or target is undefined');
      return;
    }
  
    let file;
  
    if (event.target.files && event.target.files[0]) {
      file = event.target.files[0];
      handleChange('national_id_img', file); // Update formData with the image file
    }
  
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    }
  };
  



  const handleSelectChanged = (name, isMulti = false) => (selectedOptions) => {
    const values = isMulti ? selectedOptions.map(option => option.value) : selectedOptions ? selectedOptions.value : '';
    handleChange(name, values);
  };

  
  const handleSelectChange = (name,  isMulti = false) => (selectedOption) => {
    const values = isMulti ? selectedOption.map(option => option.value) : selectedOption ? selectedOption.value : '';
    handleChange(name, values);

    // handleChange(name, selectedOption ? selectedOption.value : '');
    if (name === 'state_of_residence') {
      setSelectedState(selectedOption);
    }
    if (name === 'lga_of_residence') {
      setSelectedLocalGovt(selectedOption);
    }
  };


  useEffect(() => {
    let newError = {};
    if (formData.phone_number !== formData.confirm_phone_number) {
      newError.phone = "Phone numbers don't match";
    }
    if (formData.email !== formData.confirm_email) {
      newError.email = "Emails don't match";
    }
    setError(newError);
  }, [formData.phone_number, formData.confirm_phone_number, formData.email, formData.confirm_email]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: 'transparent',
      border: state.isFocused ? '0.78px solid #ffff' : '0.78px solid #ffff',
      height: '48px',
      boxShadow: state.isFocused ? '0 0 0 0.78px #03813E' : 'none',
      '&:hover': {
        border: '0.78px solid #03813E',
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: '#000', // Change the selected value color
    }),
    placeholder: (base) => ({
      ...base,
      color: '#575252', // Change the placeholder color
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: '#fff', // Change the dropdown arrow color
      '&:hover': {
        color: '#fff', // Change the arrow color on hover
      },
    }),
    indicatorSeparator: () => ({
      display: 'none', // Remove the vertical separator line
    }),
  };

  return (
    <>
      <div className="flex items-center gap-0 justify-between py-3">
        <div className="flex items-center justify-center text-white font-semibold rounded-full px-4 w-[70px] text-4xl h-[70px] bg-[#03813E]">3</div>
        <div className="border-[#CACACA] bg-[#CACACA] w-full h-[10px]" />

        <div className="flex items-center justify-center  font-semibold rounded-full w-[70px] px-4 text-4xl h-[70px] text-white bg-transparent border-[2px] border-solid border-[#03813E]">4</div>
      </div>

      {/* <div className="flex flex-col md:flex-row items-center justify-between w-full mb-4 gap-4  md:gap-10 lg:gap-[88px]">
                <div className="w-full flex flex-col gap-1">

          <label htmlFor="Monthly Income" className="text-base font-semibold py-[6px]">Monthly Personal Income <span className="text-red-600 pb-2">*</span></label>
          <input
            type="text"
            value={formData.monthly_income}
            onChange={(e) => handleChange('monthly_income', e.target.value)}
            required
            placeholder="Monthly Income"
            className="w-full bg-transparent pl-3 placeholder:text-[#575252] focus:border-[#03813E] focus:outline-none placeholder:text-base h-[48px] rounded-md border-solid border-[0.78px] border-[#fff]"
          />
        </div>
                <div className="w-full flex flex-col gap-1">

          <label htmlFor="Age Group" className="text-base font-semibold py-[6px]">Age Group <span className="text-red-600 pb-2">*</span></label>
          <Select
            options={ageOptions}
            styles={customStyles}
            placeholder="Choose "
            className='rounded-md'
            onChange={handleSelectChange('age_group')}
            value={ageOptions.find(option => option.value === formData.age_group)}
            required
          />
        </div>
      </div> */}

      
      
      
      
      
      
<div className="flex flex-col md:flex-row items-center md:mb-[32px] justify-between w-full mb-4 gap-4  md:gap-10 lg:gap-[88px]">
<div className="w-full ">
          <label htmlFor="certificate" className="text-base text-white py-[6px]">Any Certifications? <span className="text-red-600 pb-2">*</span></label>
        
          <Select
            options={professionCert}
            styles={customStyles}
            placeholder="Choose"
            className='rounded-md'
            onChange={handleSelectChange('number_of_certificates')}
            value={professionCert.find(option => option.value === formData.number_of_certificates)}
            required
          />
        </div>
                <div className="w-full flex flex-col gap-1">

          <label htmlFor="completion" className="text-base text-white py-[6px]">If Yes, specify completion status of certification?</label>
          <Select
            options={certificateCompletionOptions}
            styles={customStyles}
            placeholder="Choose"
            className='rounded-md'
            onChange={handleSelectChange('certificate_completion')}
            value={certificateCompletionOptions.find(option => option.value === formData.certificate_completion)}
           
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center md:mb-[32px] justify-between w-full mb-4 gap-4  md:gap-10 lg:gap-[88px]">
      <div className="w-full ">

          <label htmlFor="Emergency Contact" className="text-base text-white py-[6px]">Emergency Contact Number <span className="text-red-600 pb-2">*</span></label>
          <input
            type="text"
            value={formData.emergency_contact}
            onChange={(e) => handleChange('emergency_contact', e.target.value)}
            required
            placeholder="Enter emergency phone number"
            className="w-full pl-3 bg-transparent  focus:border-[#03813E] focus:outline-none placeholder:text-[#575252] placeholder:text-base h-[48px] rounded-md border-solid border-[0.78px] border-[#fff]"
          />
        </div>
                <div className="w-full flex flex-col gap-1">

          <label htmlFor="Guardian" className="text-base text-white py-[6px]">Name of Guardian/Next of Kin <span className="text-red-600 pb-2">*</span></label>
          <input
            type="text"
          
            value={formData.guardian_name}
            onChange={(e) => handleChange('guardian_name', e.target.value)}
            required
            placeholder="Enter Name of Guardian/Next of Kin"
            className="w-full pl-3 bg-transparent focus:border-[#03813E] focus:outline-none placeholder:text-[#575252] placeholder:text-base h-[48px] rounded-md border-solid border-[0.78px] border-[#fff]"
          />
        </div>
      </div>

      {/* <div className="flex flex-col md:flex-row items-center justify-between w-full mb-4 gap-4 md:gap-10 lg:gap-[88px]">
        
                <div className="w-full flex flex-col gap-1">

        <label htmlFor="AimOfTraining" className="text-base font-semibold py-[6px]">Why do you want to apply? Select all that apply<span className="text-red-600 pb-2">*</span></label>
<Select
    options={aimOfTraining}
    isMulti // Allow multiple selections
    styles={customStyles}
    onChange={handleSelectChange('aim_of_training', true)} // Pass true to indicate it's multi-select
    value={aimOfTraining.filter(option => formData.aim_of_training?.includes(option.value))} // Filter selected values
    placeholder="Select your aim"
    className='rounded-md'
    required
  />
        </div>
      </div> */}

      
<div className="flex flex-col md:flex-row items-center md:mb-[32px] justify-between w-full mb-4 gap-4  md:gap-10 lg:gap-[88px]">
<div className="w-full ">
          <label htmlFor="Training Source" className="text-base text-white py-[6px]">How did you hear about this training program?<span className="text-red-600 pb-2">*</span></label>
          <Select
            options={sourceOptions}
            styles={customStyles}
            onChange={handleSelectChange('how_did_you_hear')}
            value={sourceOptions.find(option => option.value === formData.how_did_you_hear)}
            placeholder="Choose"
            className='rounded-md'
            required
          />
        </div>
                <div className="w-full flex flex-col gap-1">

          <label htmlFor="Others" className="text-base text-white py-[6px]">If Others (Specify)</label>
          <input
            type="text"
            value={formData.other_source}
            onChange={(e) => handleChange('other_source', e.target.value)}
            className="w-full pl-3 bg-transparent placeholder:text-[#575252] focus:border-[#03813E] focus:outline-none placeholder:text-base h-[48px] rounded-md border-solid border-[0.78px] border-[#fff]"
          />
        </div>
      </div>
    
    
    


      <div className="flex flex-col md:flex-row items-center justify-between w-full mb-4 gap-4 md:gap-10 lg:gap-[88px]">
              <div className="w-full ">

          <label htmlFor="govID" className="text-base text-white py-[6px]">Provide your NIN <span className="text-red-600 pb-2">*</span></label>
        
           <input
            type="text"
            value={formData.government_id}
            placeholder='provide your NIN'
            onChange={(e) => handleChange('government_id', e.target.value)}
            className="w-full pl-3 bg-transparent placeholder:text-[#575252] focus:border-[#03813E] focus:outline-none placeholder:text-base h-[48px] rounded-md border-solid border-[0.78px] border-[#fff]"
          />
        </div>


        <div className="w-full flex flex-col gap-1">
    <label htmlFor="profileImage" className="text-base text-white my-[10px]">Upload NIN</label>
    
      <input type="file" 
      accept="image/*"
      
      onChange={handleImageChange}/>
    {imagePreview && (
<div className='border-solid border-[0.78px] border-[#fff] w-[100px] h-[70px] px-1 py-1 flex justify-center items-center my-3'>

<img src={imagePreview} alt="Profile Preview" className="mt-4 w-[70%] h-[70%] object-cover " />

</div>

)}
  </div>


       
      </div>
   

 









      <div className='flex items-center w-full mb-4 gap-4 pt-2'>
        <input type="checkbox" className='border-[1.5px] border-solid border-[#223345]' value="" required />
        <p className='text-xs lg:text-[16px] md:leading-[21px]'>
          I acknowledge that all the information provided by me are true, and any false information may lead to my disqualification of the program if selected.
          By applying for our digital skills training program, you consent to the collection, use, and disclosure of your personal information as described in our
          <a href="/privacy-policy" className='text-[#223345] font-semibold'> Privacy Policy.</a>
        </p>
      </div>

    </>
  );
};
