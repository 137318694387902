import React from 'react';
import Select from 'react-select'; // Import react-select
import { useState, useEffect } from 'react';
import axiosClient from '../axiosClient';

export const SecondFormPage = ({ handleChange, formData }) => {
  const [error, setError] = useState(null);
  const [courseOptions, setCourseOptions] = useState([]);

  // Options for select dropdowns
  const employmentStatus = [
    { label: "Employed", value: "Employed" },
    { label: "Self-employed", value: "Self-employed" },
    { label: "Unemployed", value: "Unemployed" }
  ];

  const aimOfTraining = [
    { label: "Skills acquisition", value: "Skills acquisition" },
    { label: "Self-development", value: "Self-development" },
    { label: "Employment", value: "Employment" }
  ];

  const physicalDisabilities = [
    { label: "YES", value: 1 },
    { label: "NO", value: 0 }
  ];
 
  const zoom = [
    { label: "YES", value: '1' },
    { label: "NO", value: '0' }
  ];
  const computer = [
    { label: "YES", value: '1' },
    { label: "NO", value: '0' }
  ];
  const computerMonths = [
    { label: "YES", value: '1' },
    { label: "NO", value: '0' }
  ];
  const ageOptions = [
    { value: '0-17', label: '0-17' },
    { value: '18-30', label: '18-30' },
    { value: '31-45', label: '31-45' },
    { value: '46-60', label: '46-60' },
    // { value: 'other', label: 'Other' },
  ];
  const monthly_incomes = [
    { value: 'N(0-100)k', label: 'N(0-100)k' },
    { value: 'N(101-200)k', label: 'N(101-200)k' },
    { value: 'N(201-300)k', label: 'N(201-300)k' },
    { value: 'N(301-400)k', label: 'N(301-400)k' },
    { value: 'N(401-500)k', label: 'N(401-500)k' },
    { value: 'Greater than N500K', label: 'Greater than N500K' },
    // { value: 'other', label: 'Other' },
  ];

  const disabilities = [
    { label: "Hearing", value: "Hearing" },
    { label: "Mobility", value: "Mobility" },
    { label: "Visual", value: "Visual" }
  ];

  const litracy = [
    { label: "None", value: "None" },
    { label: "Basic", value: "Basic" },
    { label: "Intermediate", value: "Intermediate" },
    { label: "Advanced User", value: "Advanced User" }
  ];
  const hasDisability = formData.has_disability === '1'; 

  // Custom styles for react-select
  // const customStyles = {
  //   control: (base, state) => ({
  //     ...base,
  //     backgroundColor: 'transparent',
  //     border: state.isFocused ? '0.78px solid #121010' : '0.78px solid #121010',
  //     height: '48px',
  //     boxShadow: state.isFocused ? '0 0 0 0.78px #03813E' : 'none',
  //     '&:hover': {
  //       border: '0.78px solid #03813E',
  //     },
  //   }),
  // };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: 'transparent',
      border: state.isFocused ? '0.78px solid #ffff' : '0.78px solid #ffff',
      height: '48px',
      boxShadow: state.isFocused ? '0 0 0 0.78px #03813E' : 'none',
      '&:hover': {
        border: '0.78px solid #03813E',
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: '#000', // Change the selected value color
    }),
    placeholder: (base) => ({
      ...base,
      color: '#575252', // Change the placeholder color
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: '#fff', // Change the dropdown arrow color
      '&:hover': {
        color: '#fff', // Change the arrow color on hover
      },
    }),
    indicatorSeparator: () => ({
      display: 'none', // Remove the vertical separator line
    }),
  };



  useEffect(() => {
    const fetchCourse = async () => {
      try {
        // const response = await axiosClient.get(`user/ideas-programme/countries/${countryId}/states`);
        const response = await axiosClient.get('user/ideas-programme/all_digital_skills_track');
        console.log('response is course', response.data.data)
        const options = response.data.data.map((state) => ({
          label: state.name,
          value: state.id,
         
        }));
        setCourseOptions(options);
      } catch (err) {
        setError({ ...error, fetch: "Error fetching states" });
        console.error("Error fetching states:", err);
      }
    };
    fetchCourse();
  }, []);
  

  
  // Function to handle select changes
  // const handleSelectChange = (name) => (selectedOption) => {
  //   handleChange(name, selectedOption ? selectedOption.value : '');
  // };


  // const handleSelectChange = (name, isMulti = false) => (selectedOptions) => {
  //   const values = isMulti ? selectedOptions.map(option => option.value) : selectedOptions ? selectedOptions.value : '';
  //   handleChange(name, values);
  // };

  const handleSelectChange = (name, isMulti = false) => (selectedOptions) => {
    const values = isMulti 
      ? (selectedOptions ? selectedOptions.map(option => option.value) : []) // Return an empty array if nothing is selected
      : (selectedOptions ? selectedOptions.value : ''); // Single select fallback to empty string if nothing selected
    handleChange(name, values);
  };
  

  // const handleSelectChange = (field) => (selectedOptions) => {
  //   const values = selectedOptions.map(option => option.value);
  //   setFormData(prevData => ({
  //     ...prevData,
  //     [field]: values
  //   }));
  // };
  
  return (
    <>
      <div className="flex items-center gap-0 justify-between py-3">
        <div className="flex items-center justify-center  font-semibold rounded-full w-[70px] text-4xl h-[70px] px-4 bg-transparent border-[2px] border-solid border-[#03813E]">3</div>

        <div className="border-[#CACACA] bg-[#CACACA] w-full h-[10px]" />
        <div className="flex items-center justify-center text-white font-semibold rounded-full w-[50px] px-4 md:w-[70px] text-2xl lg:text-4xl h-[60px] md:h-[70px] bg-[#03813E]">4</div>
        

      </div>

       <div className="flex flex-col md:flex-row items-center md:mb-[32px] justify-between w-full mb-4 gap-4  md:gap-10 lg:gap-[88px]">
                <div className="w-full ">

          <label htmlFor="Town" className="text-base text-white py-[6px]">Town/Locality <span className="text-red-600 pb-2">*</span></label>
          <input
            type="text"
            value={formData.town_locality}
            onChange={(e) => handleChange('town_locality', e.target.value)}
            required
            placeholder="Where do you live?"
            className="w-full pl-3 placeholder:text-[#575252] focus:border-[#03813E] bg-transparent focus:outline-none placeholder:text-base h-[48px] rounded-md border-solid border-[0.78px] border-[#fff]"
          />
        </div>
                <div className="w-full ">

          <label htmlFor="Employment Status" className="text-base text-white py-[6px]">Employment Status <span className="text-red-600 pb-2">*</span></label>
          <Select
            options={employmentStatus}
            styles={customStyles}
            onChange={handleSelectChange('employment_status')}
            value={employmentStatus.find(option => option.value === formData.employment_status)}
            placeholder="Choose"
            className='rounded-md'
            required
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-between w-full mb-4 gap-4 md:mb-[32px] md:gap-10 lg:gap-[88px]">
  
                 <div className="w-full">

        <label htmlFor="AimOfTraining" className="text-base text-white py-[6px]">Why do you want to apply? Select all that apply<span className="text-red-600 pb-2">*</span></label>
<Select
    options={aimOfTraining}
    isMulti // Allow multiple selections
    styles={customStyles}
    onChange={handleSelectChange('aim_of_training', true)} // Pass true to indicate it's multi-select
    value={aimOfTraining.filter(option => formData.aim_of_training?.includes(option.value))} // Filter selected values
    placeholder="Select your aim"
    className='rounded-md'
    required
  />
        </div>
                <div className="w-full">

          {/* <label htmlFor="Bank Account" className="text-base font-semibold py-[6px]">Do you own a Bank Account?<span className="text-red-600 pb-2">*</span></label>
          <Select
            options={accounts}
            styles={customStyles}
            onChange={handleSelectChange('owns_bank_account')}
            value={accounts.find(option => option.value === formData.owns_bank_account)}
            placeholder="Choose"
            className='rounded-md'
            required
          /> */}
                  <div className="w-full ">

          <label htmlFor="Age Group" className="text-base text-white py-[6px]">Age Group <span className="text-red-600 pb-2">*</span></label>
          <Select
            options={ageOptions}
            styles={customStyles}
            placeholder="Choose "
            className='rounded-md'
            onChange={handleSelectChange('age_group')}
            value={ageOptions.find(option => option.value === formData.age_group)}
            required
          />
        </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-between md:mb-[32px] w-full mb-4 gap-4 md:gap-10 lg:gap-[88px]">
        
                <div className="w-full ">

          <label htmlFor="Monthly Income" className="text-base text-white py-[6px]">Monthly Personal Income <span className="text-red-600 pb-2">*</span></label>
         
          <Select
            options={monthly_incomes}
            styles={customStyles}
            onChange={handleSelectChange('monthly_income')}
            value={monthly_incomes.find(option => option.value === formData.monthly_income)}
            placeholder="Choose"
            className='rounded-md'
            required
          />
        </div>
        <div className="w-full ">

<label htmlFor="Computer Literacy" className="text-base text-white py-[6px]">What is your Computer Literacy level?<span className="text-red-600 pb-2">*</span></label>
<Select
  options={litracy}
  styles={customStyles}
  onChange={handleSelectChange('computer_literacy')}
  value={litracy.find(option => option.value === formData.computer_literacy)}
  placeholder="Choose"
  className='rounded-md'
  required
/>
</div>
                
                
           
      </div>

       <div className="flex flex-col md:flex-row items-center md:mb-[32px] justify-between w-full mb-4 gap-4  md:gap-10 lg:gap-[88px]">
               
       <div className="w-full ">

<label htmlFor="Physical Disability" className="text-base text-white py-[6px]">Any Physical Disability?<span className="text-red-600 pb-2">*</span></label>
<Select
  options={physicalDisabilities}
  styles={customStyles}
  onChange={handleSelectChange('has_disability')}
  value={physicalDisabilities.find(option => option.value === formData.has_disability)}
  placeholder="Choose"
  className='rounded-md'
  required
/>
</div>
               
               
               
                <div className="w-full ">

          <label htmlFor="Disability Type" className="text-base text-white py-[6px]">If YES, mention type of disability</label>
          <Select
            options={disabilities}
            styles={customStyles}
            onChange={handleSelectChange('disability_type')}
            value={disabilities.find(option => option.value === formData.disability_type)}
            placeholder="Choose"
            className='rounded-md'
            required={hasDisability}
          />
        </div>
             
      </div>

       <div className="flex flex-col md:flex-row items-center md:mb-[32px] justify-between w-full mb-4 gap-4  md:gap-10 lg:gap-[88px]">
                <div className="w-full ">

          <label htmlFor="Zoom Use" className="text-base text-white py-[6px]">Can you use Zoom, Microsoft Teams or Google Meet?<span className="text-red-600 pb-2">*</span></label>
          <Select
            options={zoom}
            styles={customStyles}
            onChange={handleSelectChange('can_use_zoom_teams_meet')}
            value={zoom.find(option => option.value === formData.can_use_zoom_teams_meet)}
            placeholder="Choose"
            className='rounded-md'
            required
          />
        </div>
        

                <div className="w-full ">

          <label htmlFor="computer" className="text-base text-white py-[6px]">Do you own a Laptop? <span className="text-red-600 pb-2">*</span></label>
          <Select
            options={computer}
            styles={customStyles}
            onChange={handleSelectChange('has_computer_for_training')}
            value={computer.find(option => option.value === formData.has_computer_for_training)}
            placeholder="Choose"
            className='rounded-md'
            required
          />
        </div>
      </div>

       <div className="flex flex-col md:flex-row items-center md:mb-[32px] justify-between w-full mb-4 gap-4  md:gap-10 lg:gap-[88px]">
                <div className="w-full ">

          <label htmlFor="Program Duration" className="text-base text-white py-[6px]">The program is slated for three (3) months. Would you be available for this duration or not?<span className="text-red-600 pb-2">*</span></label>
          <Select
            options={computerMonths}
            styles={customStyles}
            onChange={handleSelectChange('available_for_six_months')}
            value={computer.find(option => option.value === formData.available_for_six_months)}
            placeholder="Choose"
            className='rounded-md'
            required
          />
        </div>
                <div className="w-full ">

          <label htmlFor="Digital Skills" className="text-base text-white py-[6px]">Select Course of interest?<span className="text-red-600 pb-2">*</span></label>
          <Select
            options={courseOptions}
            styles={customStyles}
            onChange={handleSelectChange('digital_skills_track_id')}
            value={courseOptions.find(option => option.value === formData.digital_skills_track_id)}
            placeholder="Choose"
            className='rounded-md'
            required
          />
        </div>
      </div>

     
      

      {/* <div className="flex flex-col md:flex-row items-center justify-between w-full mb-4 gap-4 md:gap-10 lg:gap-[88px]">
              <div className="w-full ">

        <label htmlFor="AimOfTraining" className="text-base font-semibold py-[6px]">What is your aim of undertaking this training? Select all that apply<span className="text-red-600 pb-2">*</span></label>
<Select
    options={aimOfTraining}
    isMulti // Allow multiple selections
    styles={customStyles}
    onChange={handleSelectChange('aim_of_training', true)} // Pass true to indicate it's multi-select
    value={aimOfTraining.filter(option => formData.aim_of_training?.includes(option.value))} // Filter selected values
    placeholder="Select your aim"
    className='rounded-md'
    required
  />
        </div>
        
      </div> */}

     
         </>
  );
};
